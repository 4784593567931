<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Voucher No</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in journals" :key="i">
                        <td>
                            <span class="fw-bold">{{ item.date }}</span>
                        </td>
                        <td>{{ item.voucher_no }}</td>
                        <td> {{item.note}} </td>
                        <td> {{ commaFormat(item.total_amount) }} </td>
                        <td @click="goToEdit(item.id)">
                            <span style="cursor: pointer">View / Edit</span> 
                            <!-- <div class="dropdown">
                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0 waves-effect waves-float waves-light" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end" style="">
                                    <a class="dropdown-item" href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 me-50"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        <span>Edit</span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash me-50"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                        <span>Delete</span>
                                    </a>
                                </div>
                            </div> -->
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            <p v-if="!journals.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import figureFormatter from '@/services/utils/figureFormatter'

export default {
    name: 'JournalTable',
    computed: {
        ...mapGetters ({
            journals: 'journal/getJournals'
        })
    },
    setup() {
        const {commaFormat} = figureFormatter ()

        return {
            commaFormat
        }
    },
    methods: {
        goToEdit(id) {
            this.$router.push({name: 'EditJournal', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId,
                    journalId: id
                },
                query: {
                    start: this.$route.query.start,
                    end:  this.$route.query.end
                }
            
            })
        }
    }
}
</script>
